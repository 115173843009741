<script setup lang="ts">

</script>

<template>
  <div>
    <div class="container mx-auto px-8 flex flex-col lg:flex-row lg:justify-center items-center space-y-4 lg:space-y-0 lg:space-x-8 font-sans2 py-12">
      <div class="flex flex-col items-center lg:items-start text-glueblue-600 font-bold uppercase font-wff-headline text-lg tracking-widest">
        <div>Get Ready for</div>
        <a href="https://www.instagram.com/npiwff/?hl=en" target="_blank" class="flex items-center space-x-4 text-4xl">
          <svg
            xmlns="http://www.w3.org/2000/svg" class="w-12 h-12"
            viewBox="0 0 24 24"
          >
            <path
              d="M21.231 0h-18.462c-1.529 0-2.769 1.24-2.769 2.769v18.46c0 1.531 1.24 2.771 2.769 2.771h18.463c1.529 0 2.768-1.24 2.768-2.771v-18.46c0-1.529-1.239-2.769-2.769-2.769zm-9.231 7.385c2.549 0 4.616 2.065 4.616 4.615 0 2.549-2.067 4.616-4.616 4.616s-4.615-2.068-4.615-4.616c0-2.55 2.066-4.615 4.615-4.615zm9 12.693c0 .509-.413.922-.924.922h-16.152c-.511 0-.924-.413-.924-.922v-10.078h1.897c-.088.315-.153.64-.2.971-.05.337-.081.679-.081 1.029 0 4.079 3.306 7.385 7.384 7.385s7.384-3.306 7.384-7.385c0-.35-.031-.692-.081-1.028-.047-.331-.112-.656-.2-.971h1.897v10.077zm0-13.98c0 .509-.413.923-.924.923h-2.174c-.511 0-.923-.414-.923-.923v-2.175c0-.51.412-.923.923-.923h2.174c.511 0 .924.413.924.923v2.175z"
              fill-rule="evenodd" clip-rule="evenodd" fill="currentColor"
            />
          </svg>
          <span>@NPIWFF</span>
        </a>
        <div>#NPIWFF</div>
      </div>
      <div class="text-glueblue-600 text-center">
        <div class=" font-bold lowercase font-wff-headline text-2xl">
          March 12 - 16, 2025
        </div>
        <div class="px-4 text-sm">
          Join us in paradise for the best in food, wine, live entertainment and so much more.
        </div>
      </div>
      <div class="flex justify-center items-center lg:justify-start">
        <WffGlasses class="w-72" />
      </div>
    </div>

    <div class="overflow-hidden">
      <SlidersOlapicSlider arrow-color="zinc" :devise="{ stream: { text: 2226160865 } }" />
    </div>

    <div class="pt-6 container flex justify-center">
      <ul
        class="uppercase flex items-center space-x-8 text-sm font-sans2 font-normal  border-t border-glueblue-700 pt-8 px-12"
      >
        <li>
          <a
            href="/wine-and-food-festival/events"
            class="text-glueblue-600"
          >Events</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/chefs" class="text-glueblue-600">Talent</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/faqs" class="text-glueblue-600">Info &amp;
            FAQs</a>
        </li>
        <li>
          <a
            href="/wine-and-food-festival/packages"
            class="text-glueblue-600"
          >Packages</a>
        </li>
        <li>
          <a
            href="https://secure.atlantisbahamas.com/travel/arc_waiting.cfm?nav=apiWebCartoonNetwork&origin=http%253A%252F%252FAtlantisBahamas.com%252F&activity1=true&area2=%7Cairport%253ANAS&displayarea2=NAS&date1=03%2F13%2F2024&date2=03%2F17%2F2024&submit=Search"
            class="text-glueblue-600"
          >Tickets</a>
        </li>
      </ul>
    </div>

    <div class="flex justify-center mt-8">
      <LogosAtlantisLogo class="w-48 text-glueblue-600" />
    </div>

    <div class="text-xs text-center mt-12 font-sans2">
      <div>
        <span class="border-r border-glueblue-600 last:border-r-0 pr-1 mr-1"><a
          href="https://www.atlantisbahamas.com/contact"
          class="text-glueblue-600"
        >Contact Us</a></span>
        <span class="border-r border-glueblue-600 last:border-r-0 pr-1 mr-1"><a
          href="https://www.atlantisbahamas.com/terms"
          class="text-glueblue-600"
        >Terms
          &amp; Conditions</a></span><span
          class="border-r border-glueblue-600 last:border-r-0 pr-1 mr-1"
        ><a
          href="https://www.atlantisbahamas.com/privacy"
          class="text-glueblue-600"
        >Privacy</a></span><span
          class="border-r border-glueblue-600 last:border-r-0 pr-1 mr-1"
        ><a
          href="/sitemap" class="text-glueblue-600"
        >Site Map</a></span>
      </div>
      <div class="mt-1">
        <span
          class="border-r border-glueblue-600 last:border-r-0 pr-1 mr-1"
        ><a
          href="javascript:void(0)" class="ot-sdk-show-settings"
        > Cookie
          Settings
        </a></span><span
          class="border-r border-glueblue-600 last:border-r-0 pr-1 mr-1"
        ><a
          href="https://privacyportal.onetrust.com/webform/1a83e5ad-9ea4-49fa-91d0-295c0bddffd1/06533f77-836b-420f-bac4-74f4315462e8"
        >Exercise
          Your Privacy Rights</a></span><span
          class="border-r border-glueblue-600 last:border-r-0 pr-1 mr-1"
        ><a
          href="https://privacyportal.onetrust.com/webform/1a83e5ad-9ea4-49fa-91d0-295c0bddffd1/06533f77-836b-420f-bac4-74f4315462e8"
        >Do
          not sell my information</a></span><span
          class="border-r border-glueblue-600 last:border-r-0 pr-1 mr-1"
        ><a
          href="tel:1-800-285-2684"
          class="text-gray-800"
        >1-800-Atlantis</a></span>
      </div>
    </div>
    <div class="pb-16 px-16 text-xs text-center mt-3 text-glueblue-700 opacity-75">
      ©
      Atlantis.
      All rights reserved.
    </div>
  </div>
</template>
