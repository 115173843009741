<script setup lang="ts">
const open = ref(false)
</script>

<template>
  <div>
    <div class="flex items-center justify-between bg-white py-6 px-8 big-container font-sans2">
      <div class="flex space-x-4">
        <button class="xl:hidden text-glueblue-600" @click="open = true">
          <svg
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round" stroke-linejoin="round"
              d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
            />
          </svg>
        </button>
        <a href="/wine-and-food-festival">
          <img src="/imgs/wff/npiwff-logo.png" alt="NPIWFF Logo" class="w-48">
        </a>
      </div>
      <ul class="uppercase hidden xl:flex items-center space-x-4 lg:space-x-8 pr-8 text-sm  font-wff-headline font-bold">
        <li>
          <a href="/wine-and-food-festival/events" class="text-glueblue-600">Events</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/chefs" class="text-glueblue-600">Talent</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/faqs" class="text-glueblue-600 whitespace-nowrap text-ellipsis overflow-hidden">Info &amp; FAQs</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/packages" class="text-glueblue-600">Packages</a>
        </li>
        <!-- <li>
          <a href="/wine-and-food-festival/sponsors" class="text-glueblue-600">Sponsors</a>
        </li> -->
        <li>
          <a href="/wine-and-food-festival/event-photos" class="text-glueblue-600">Gallery</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/a-culinary-adventure" class="text-glueblue-600 whitespace-nowrap text-ellipsis overflow-hidden">Culinary Stories</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/hotels" class="text-glueblue-600 whitespace-nowrap text-ellipsis overflow-hidden">Explore
            Paradise</a>
        </li>
      </ul>
    </div>

    <div
      class="fixed z-[99999] top-0 left-0 h-screen w-screen bg-faw-light-blue p-8 font-sans2 text-slate-600 transform transition-all duration-300 ease-in-out"
      :class="{ '-translate-x-full': !open, 'translate-x-0': open }"
    >
      <div class="bg-white -mx-8 -mt-8 p-8 pb-0">
        <div class="flex items-center justify-between border-b border-glueblue-600 pb-4">
          <a href="/wine-and-food-festival">
            <img src="/imgs/wff/npiwff-logo.png" alt="NPIWFF Logo" class="w-40">
          </a>
          <button class="text-glueblue-600" @click="open = false">
            <svg
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              class="w-6 h-6"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>

      <ul class="pt-8 uppercase items-center space-y-4">
        <li>
          <a href="/wine-and-food-festival" class="text-glueblue-600">Festival</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/events" class="text-glueblue-600">Events</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/chefs" class="text-glueblue-600">Talent</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/faqs" class="text-glueblue-600">Info &amp; FAQs</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/packages" class="text-glueblue-600">Packages</a>
        </li>
        <!-- <li>
          <a href="/wine-and-food-festival/sponsors" class="text-glueblue-600">Sponsors</a>
        </li> -->
        <li>
          <a href="/wine-and-food-festival/event-photos" class="text-glueblue-600">Gallery</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/a-culinary-adventure" class="text-glueblue-600">Culinary Stories</a>
        </li>
        <li>
          <a href="/wine-and-food-festival/hotels" class="text-glueblue-600">Explore
            Paradise</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>
